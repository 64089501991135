import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "pages/tr/SloganFeature.js";
import MainFeature from "pages/tr/About.js";
import FeatureStats from "pages/tr/LupiHeader.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "pages/tr/Reach.js";
import SMFeature from "pages/tr/SocialMediaManagement";
import GDFeature from "pages/tr/GameDevelopment.js";
import Companies from "./en/Companies";
import Services from "pages/tr/Services.js";
// import Title from "../HeaderEn.js";
import Title from "pages/tr/Header";
import Footer from "./tr/FooterDark";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
const HighlightedText = tw.span`text-yellow-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Title />

      {/* <Routes>
    <Route path="/" element= {<Services />}>
      <Route path="services" element = {<Services />} />
     <Route path="companies" element = {<Companies />} />
      </Route>
    
    <Route path="*" element={<Navigate to="/xyz" />} />
  </Routes> */}

      <Features
        heading={
          <>
            <HighlightedText>Kapsayıcı</HighlightedText> şekilde severiz
          </>
        }
      />

      <FeatureStats />

      <SMFeature
        heading={"Sosyal Medya Yönetimi"}
        subheading={"İlgilenir miydiniz?"}
        description={
          "Pluto Socio, bizim sosyal medya yönetimi markamızdır. 4 farklı müşteri grubu için hazırlanmış paketler sunmaktadır. Bu müşteri grupları; e-ticaret işletmeleri, özelleştirilmiş içerik arayan işletmeler, günlük içerik arayan profesyoneller ve bireysel hesaplardan oluşmaktadır."
        }
      />
      <GDFeature />
      <Services />
      <MainFeature />

      <GetStarted />

      <Footer />
    </AnimationRevealPage>
  );
};
