import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "pages/en/GameDevelopment.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm text-green-500`;
export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
        <Features
        subheading={<Subheading>İLGİLENDİĞİMİZ</Subheading>}
        heading="Oyun Türleri"
        description="Özel olarak ilgilendiğimiz bazı alanlar var. Bu alanlara olan ilgimizin bizi farklı kıldığına ve bunlara odaklanmamız gerektiğine inanıyoruz."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "RPG",
            description: "Rol yapma oyunlarının gelmişini geçmişini ve özellikle geleceğini sık sık düşünüyoruz."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Casual Mobile",
            description: "Casual mobil oyunların günlük hayatın stresini atmaktaki yardımcı etkisine hayranız."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Kutu Oyunlar",
            description: "Kutu oyunların, bayramdaki ve seyrandaki kuzen buluşmalarının en güzel mezesi olduğuna inanıyoruz."
          },
        ]}
        linkText=""
      />
      <MainFeature1
        subheading={<Subheading>OYUN GELİŞTİRİRKEN
        </Subheading>}
        heading="Her şeyi kendimiz üretiyoruz"
        description="Fabrika misali seri üretime geçmektense, eşi benzeri olmayan özgün ürünler üretmeyi tercih ediyoruz. Oyun geliştirme sürecinde her şeyi stüdyomuzda üretiyoruz."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/5806221/pexels-photo-5806221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
      <MainFeature1
        subheading={<Subheading>AMACIMIZ</Subheading>}
        heading="Raflarımızdaki tüm oyun projelerini gerçekleştirmek"
        description="Kendini tekrar eden, öngörülebilir oyunlar ilgi alanımıza girmemekte. Her başlangıcın yeni bir hikayeye açıldığı, her hikayenin oyuncuya ait olduğu algoritmalar yazmayı seviyoruz."

        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.pexels.com/photos/6368802/pexels-photo-6368802.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        textOnLeft={false}
      />
       <MainFeature1
        subheading={<Subheading>FARKIMIZ
        </Subheading>}
        heading="Oynamak için üretişimiz"
        description="RASITGR Games ekibi olarak ilk önceliğimiz oynanış ve fikirlerimizi üretmek. Oyunlarımızı maddi kaygılarla değil, oynanış arzusuyla şekillendiriyoruz. Oynamak için geliştirilen oyunların samimiyetine ve eğlencesine inanıyoruz."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/1550650/pexels-photo-1550650.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
      <MainFeature1
        subheading={<Subheading>BİZİ BİZ YAPAN</Subheading>}
        heading="Sıfır bütçe ile size ulaşmamız"
        description="RASITGR Games; oyun oynamayı seven, oyunların insanlara yardım ettiğini düşünen bir ekibin ekstra çabalarıyla üretiyor ve size ulaşıyor. Dışarıdan hiçbir hizmet almayışımız ve her şeyi beraber öğrenerek geliştirmemiz bizi biz yapıyor."

        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.pexels.com/photos/6157059/pexels-photo-6157059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        textOnLeft={false}
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
