import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";
import Rasitgr from "./demos/CrazyDemagogyWars";
import Title from "./HeaderEn";
import Home from "./Home.js";
import Footer from "./pages/en/FooterDark"
import MainEn from "./pages/MainEn";
import MainTr from "./pages/MainTr";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage";
import Services from "./pages/en/Services";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Companies from "pages/en/Companies";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import Disclaimer from "pages/Disclaimer";

export default function App() {

  return ( 
   <>
   <GlobalStyles />
    
         <Routes>
          <Route exact path="/" element={<Navigate to="/en" />}/>

          <Route exact path='/en' element={<MainEn />}  />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}  />
          <Route exact path='/terms-and-conditions' element={<TermsOfService />}  />
          <Route exact path='/disclaimer' element={<Disclaimer />}  />

          {/* <Route path="tr" element={<MainTr />} /> */}
    
        </Routes>
     
   </> 
  );
}