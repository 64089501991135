import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "pages/en/FooterDark";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-300 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-400`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: September 28, 2024</p>

            <p>
              This Privacy Policy outlines how Lupi, owned by Alp Kurt, collects and uses data through the website. Lupi is committed to ensuring the privacy and protection of any information collected. The only data collected is through Google Analytics, which helps improve the website's user experience and performance.
            </p>

            <h1>Information We Collect</h1>
            <p>
              Lupi collects anonymous data through Google Analytics to monitor website traffic and performance. The information collected includes:
            </p>
            <ul>
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Pages visited and time spent on the website</li>
              <li>Geographical location</li>
              <li>Device information</li>
            </ul>
            <p>
              This data is collected anonymously and used solely for statistical purposes to improve the website’s functionality.
            </p>

            <h1>Google Analytics</h1>
            <p>
              Lupi uses Google Analytics to collect, analyze, and monitor user interaction with the website. The data collected is anonymous and helps improve the overall user experience.
            </p>
            <p>
              You can learn more about Google Analytics and how Google uses this data by visiting the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
            </p>

            <h1>Data Protection and Security</h1>
            <p>
              Lupi takes data security seriously and implements the following measures:
            </p>
            <ul>
              <li>Secure Socket Layer (SSL) encryption</li>
              <li>Restricted access to data</li>
            </ul>
            <p>
              While we take necessary steps to protect data, please note that no method of transmission over the Internet is entirely secure.
            </p>

            <h1>Cookies</h1>
            <p>
              Lupi may use cookies through Google Analytics to analyze how users interact with the site. Cookies do not store any personally identifiable information. You can manage cookies through your browser settings.
            </p>

            <h1>Changes to This Privacy Policy</h1>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly.
            </p>

            <h1>Contact Us</h1>
            <p>
              If you have any questions or concerns about this Privacy Policy, you can contact us at:
            </p>
            <ul>
              <li>Email: info@lupittus.com</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};


