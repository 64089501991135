import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import KeremPhoto from 'images/lupittus/kerem-min.png'
import GizoPhoto from 'images/lupittus/gizo-min.png'
import ErgunPhoto from 'images/lupittus/horsbtcher-min.jpg'
import AlpPhoto from 'images/lupittus/pap-min.png'
import Banner from 'images/lupittus/lupi-banner-min.png'

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-gray-300`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-400`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold text-gray-300`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-500`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0 text-gray-400`;
const CustomerName = tw.h5`font-bold text-xl text-gray-300`;
const CustomerTitle = tw.p`font-medium text-gray-400`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-900 text-gray-900 hover:text-gray-200 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = Banner,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Let's See",
  heading = "Our Team & Mission!",
  description = "At LUPI, our mission is to push the boundaries of innovation and creativity in the digital world. We aim to deliver exceptional products and services by blending design, technology, and storytelling. From immersive game experiences to cutting-edge web development, our goal is to meet the diverse needs of our clients while advancing the future of digital interactive design. We are dedicated to exploring the latest trends in digital creativity and AI, providing our clients with solutions that are not only functional but also inspiring.",
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc:
        KeremPhoto,
      heading: "Amazing User Experience",
      quote:
        "Kerem, an early member of LUPI, brings creativity and technical prowess in 3D arts and design. He leads LUPI’s 3D projects, creating characters, environments, and animations that bring our digital worlds to life. Kerem also dabbles in game programming and design, ensuring that the visual elements and gameplay align seamlessly. His dedication to delivering high-quality 3D assets plays a crucial role in LUPI’s innovative offerings.",
      customerName: "Kerem Kurt",
      customerTitle: "3D Artist & Animator"
    },
    {
      stars: 5,
      profileImageSrc:
        ErgunPhoto,
      heading: "Love the Developer Experience and Design Principles !",
      quote:
        "Ergün is passionate about game development, having studied Italian Language and Literature but immersing himself in game programming, design, and art. His role focuses on ensuring that the gameplay mechanics are not only functional but also aligned with the creative vision of the games. Ergün plays a key role in programming, ensuring that the software is robust and responsive, while also contributing to game design and overall project development.",
      customerName: "Ergünalp Kurt",
      customerTitle: "Game Developer & Designer"
    },
    {
      stars: 5,
      profileImageSrc:
       GizoPhoto,
      heading: "Love the Developer Experience and Design Principles !",
      quote:
        "Gizem holds a Bachelor's in Translation from Istanbul, an Associate's in Social Media Management, and a Master's in New Media Design from Berlin. At LUPI, she wears multiple hats, contributing to UI/UX design, graphic design, and product design. Gizem ensures that the user experience is intuitive and that the design elements are cohesive. Additionally, she plays a significant role in shaping the digital marketing strategies, ensuring LUPI’s projects reach the right audience.",
      customerName: "Gizem Zengin",
      customerTitle: "UI & UX Designer, Digital Designer"
    },
    {
      stars: 5,
      profileImageSrc:
       AlpPhoto,
      heading: "Love the Developer Experience and Design Principles !",
      quote:
        "Alp studied Translation in Ankara and holds a master's in New Media Design in Berlin, bringing a unique mix of creative and technical expertise to LUPI. As the project director, Alp oversees all facets of LUPI’s operations, from game development to digital design. He ensures that each project aligns with the team's vision and the client’s needs. Alp also handles the online presence of LUPI, RASITGR Games, and Pluto Socio, managing websites and ensuring a coherent brand identity across all platforms.",
      customerName: "Alp Kurt",
      customerTitle: "Project Director, Web & Game Developer, Digital Designer"
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description> <br />
            <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                
                <Testimonial key={index}>
                  {/* <StarsContainer>
                    {Array.from({ length: testimonial.stars }).map((_,indexIcon) => (
                      <StarIcon key={indexIcon} />
                    ))}
                  </StarsContainer> */}
                  {/* <TestimonialHeading>{testimonial.heading}</TestimonialHeading> */}
                  
                  <CustomerInfo>
                      <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />
                      <CustomerTextInfo>
                        <CustomerName>{testimonial.customerName}</CustomerName>
                        <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                      </CustomerTextInfo>
        
                    </CustomerInfo>
                    
                  <Quote>{testimonial.quote}</Quote>
               
               
                   
                  
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
