import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Importing Firebase functions from the modular SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

Modal.setAppElement("#root");

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAt5h8eAjJhQkJyxOe71nJuySlzvve-bg",
  authDomain: "lupittus-4d63a.firebaseapp.com",
  databaseURL: "https://lupittus-4d63a-default-rtdb.firebaseio.com",
  projectId: "lupittus-4d63a",
  storageBucket: "lupittus-4d63a.appspot.com",
  messagingSenderId: "166341763907",
  appId: "1:166341763907:web:a1ed615230fb251f7f05f6",
  measurementId: "G-GMQLKRFQ0Z"
};

// Initialize Firebase and Analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <App />
  </Router>
);
