import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import lupiLogo from 'images/lupittus/lupi.png';
import rasitgr from 'images/lupittus/rasitgr.png';
import pluto from 'images/lupittus/pluto.jpg';

const Container = tw.div`relative bg-gray-900 px-8 -mx-8 `;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left text-gray-300`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-gray-300`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-400`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0 text-gray-300`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left text-gray-300`;
const FeatureHeading = tw.div`font-bold text-lg text-gray-300`;
const FeatureDescription = tw.div`mt-1 text-sm text-gray-400`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      We have the most versatile design and development team.
    </>
  ),
  description = "We are a small, innovative indie team that began our journey in the historical city of Amasya. Thanks to support from the Turkish Government's Small and Medium Size Organization Development Agency, we expanded beyond our borders, leading to the creation of two unique branches: RASITGR Games for publishing our indie titles and Pluto Socio, offering brand and social media management services.",
  description2="Our team works project based & remotely across various locations, united by our passion for digital interactive design. We're committed to pushing the boundaries of technology, constantly exploring the latest trends in digital creativity and AI",
  primaryButtonText = "See Our Demo Reel",
  primaryButtonUrl = "",
  features = null,
  textOnLeft = true
}) => {

  const defaultFeatures = [
    {
      Icon: rasitgr,
      title: "RASITGR Games",
      description: "We publish our indie titles and offer game development services.", url:"https://rasitgr.com", color:"#48BB78"
    },
    {
      Icon: pluto,
      title: "Pluto Socio",
      description: "We offer holistic brand management and digital marketing services.", url:"https://plutosocio.com", color:"#7c5cbc"
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc= {lupiLogo} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <Subheading>{subheading}</Subheading> */}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Description>{description2}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  {/* <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer> */}
                  <FeatureText>
                    <a target="_blank" href={feature.url}><FeatureHeading style={{color:feature.color}}>{feature.title}</FeatureHeading></a>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
