import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "pages/en/SloganFeature.js";
import MainFeature from "pages/en/About.js";
import FeatureStats from "pages/en/LupiHeader.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "pages/en/Reach.js";
import TomeOfArcuray from "pages/en/SocialMediaManagement";
import GDFeature from "pages/en/GameDevelopment.js";
import Companies from "./en/Companies";
import Services from "pages/en/Services.js";
// import Title from "../HeaderEn.js";
import Title from "pages/en/Header";
import Footer from "./en/FooterDark";
import Team from 'pages/en/Team';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Description from "./en/Description";
import SingleCol from "components/faqs/SingleCol.js";
import Timeline from "pages/en/Timeline";
import QuarantineChronicles from "./en/QuarantineChronicles";
const HighlightedText = tw.span`text-yellow-500`;

export default () => {
  return (
    <AnimationRevealPage disabled={true}>
      
      <Title
      heading="your versatile companion"
      />

      <Description />

      <Features />

      <Team />

      <Timeline  />

      <FeatureStats />

      <TomeOfArcuray
        heading={"Tome Of Arçuray"}
        subheading={"Digital Design Case Study"}
        description={
          "A transmedia narrative design covering Arçuray, a deity from Turkish myth and his spellbook. There are multiple extensions that expands the story of Arçuray. We made sure to get the most out of every medium we used."
        }
      />
      <GDFeature />

      <QuarantineChronicles />

      {/* <Services /> */}
      {/* <MainFeature /> */}

      <GetStarted />

      <Footer />
    </AnimationRevealPage>
  );
};
