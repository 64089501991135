import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "pages/en/FooterDark";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-300 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-400`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms and Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: September 28, 2024</p>

            <p>Please read these terms and conditions carefully before using Our Service.</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              The words with capitalized initial letters have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares or other securities entitled to vote for election of directors.
              </li>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Lupi, owned by Alp Kurt.
              </li>
              <li>
                <strong>Country</strong> refers to Germany or Turkey depending on the user’s location.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Terms and Conditions</strong> mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <strong>Website</strong> refers to Lupi, accessible from https://lupittus.com.
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service.
              </li>
            </ul>

            <h1>Acknowledgement</h1>
            <p>
              These Terms and Conditions govern the use of this Service and form the agreement between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
            </p>
            <p>
              By accessing or using the Service, You agree to be bound by these Terms. If You disagree with any part of these Terms, You may not access the Service.
            </p>
            <p>
              You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
            </p>

            <h1>Links to Other Websites</h1>
            <p>
              Our Service may contain links to third-party websites or services that are not owned or controlled by the Company. We assume no responsibility for the content, privacy policies, or practices of any third-party websites.
            </p>

            <h1>Termination</h1>
            <p>
              We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms.
            </p>
            <p>Upon termination, Your right to use the Service will cease immediately.</p>

            <h1>Limitation of Liability</h1>
            <p>
              To the maximum extent permitted by applicable law, in no event shall the Company be liable for any special, incidental, indirect, or consequential damages arising from Your use of the Service.
            </p>

            <h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
            <p>
              The Service is provided to You "AS IS" and "AS AVAILABLE" without any warranties of any kind. The Company makes no warranty that the Service will meet Your requirements, be uninterrupted, or error-free.
            </p>

            <h1>Governing Law</h1>
            <p>
              The laws of Turkey or Germany, depending on the user’s location, shall govern these Terms and Your use of the Service.
            </p>

            <h1>Changes to These Terms and Conditions</h1>
            <p>
              We reserve the right to modify these Terms at any time. Any changes will be posted on this page.
            </p>

            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us by email at:</p>
            <ul>
              <li>Email: info@lupittus.com</li>
            </ul>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

