import React from 'react'
import SMFeature from "pages/en/SocialMediaManagement";
import GDFeature from "pages/en/GameDevelopment.js";
import styled from "styled-components";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

function Companies() {
  return (
    <AnimationRevealPage>
        <SMFeature />
        <GDFeature />
    </AnimationRevealPage>
  )
}

export default Companies